<template>
  <div class="all">
    <BandHeader active-index="/band"></BandHeader>
    <div class="main">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide>
          <router-link to="/product1">
          <div class="slide-inner" :style="swiper1"
               style="height: 45vw;width: 100%;min-width: 960px;min-height: 436px;position: relative;"></div>
          </router-link>
        </swiper-slide>
        <swiper-slide>
          <router-link to="/product2">
          <div class="slide-inner" :style="swiper2"
               style="height: 45vw;width: 100%;min-width: 960px;min-height: 436px;position: relative"></div>
          </router-link>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <div style="margin: 50px 5%;">
        <router-link to="/product">
          <div class="tile">
            <div class="text">
              <h2>
                关心健康
              </h2>
              <h4>
                你的运动健身助手！
              </h4>
              <p class="animate-text">智能运动监测技术、海量专业动作知道视频、个性化而课程定制、智能训练计划、丰富健康食谱搭配推荐、丰富的运动经验搭分享。</p>
              <img src="../../assets/Band/3.jpg"/>
            </div>
          </div>
        </router-link>

        <router-link to="/product">
          <div class="tile">
            <div class="text">
              <h2>
                关爱老人
              </h2>
              <h4>
                感动触手可及！
              </h4>
              <p class="animate-text">实时观测老人心率，心率、睡眠、血氧、血压、计步、 运动轨迹与频率、 手环定位、 一键呼叫、电子围栏，趣味性康复教程等</p>
              <img src="../../assets/Band/2.jpg"/>
            </div>
          </div>
        </router-link>

        <router-link to="/product">
          <div class="tile">
            <div class="text">
              <h2>
                关注睡眠
              </h2>
              <h4>
                24小时全天候检测
              </h4>
              <p class="animate-text">记录睡眠情况，根据深浅睡眠时间，分析睡眠质量，睡眠健康画像，分析睡眠影响原因，提供针对性的睡眠康复方案</p>
              <img src="../../assets/Band/4.jpg"/>
            </div>
          </div>
        </router-link>

        <router-link to="/product">
          <div class="tile">
            <div class="text">
              <h3>
                关怀儿童
              </h3>
              <h4>
                让宝贝健康快乐成长！
              </h4>
              <p class="animate-text">计步、定位、运动姿势提醒等，有趣的体感互动与丰富的亲子互动视频，学习与娱乐时间管理提醒功能。</p>
              <img src="../../assets/Band/5.jpg"/>
            </div>
          </div>
        </router-link>
      </div>

      <h3 style="margin-top: 20px">产品推荐</h3>
      <p style="border: 3px solid #3e3e3e;border-radius:5px;width: 40px;margin: 10px auto"></p>
      <h3 style="margin-bottom: 20px">RECOMMEND</h3>

      <router-link to="/product1">
        <el-image :src="require('../../assets/Band/产品推荐1760-800.jpg')" lazy style="border: 1px solid #e5e5e5;border-radius: 4px;text-align:center;width: 80vw;height: 36.4vw;
            min-width: 768px;min-height: 350px;margin-bottom: 20px"/>
      </router-link>
      <router-link to="/product2">
        <el-image :src="require('../../assets/Band/7.jpg')" lazy style="border: 1px solid #e5e5e5;border-radius: 4px;width: 80vw;height: 36.4vw;min-width: 768px;
            min-height: 350px;margin-bottom: 50px"/>
      </router-link>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Footer";
import BandHeader from "../../components/BandHeader";

export default {
  name: "Band",
  data() {
    return {
      swiperOption: {
        loop: true,
        //键盘
        keyboard: {
          enabled: true,
        },
        speed: 1500,
        centeredSlides: true,
        autoplay: {
          autoplay: true,
          delay: 2000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      },
      swiper1: {
        background: 'url(' + require('../../assets/Band/星火海报1920-960.jpg') + ')',
        backgroundSize: '100% 100%',
      },
      swiper2: {
        background: 'url(' + require('../../assets/Band/7.jpg') + ')',
        backgroundSize: '100% 100%',
      },
    }
  },

  components: {
    BandHeader, Footer
  },

  props: {
    activeIndex: {
      type: String
    }
  },

}
</script>

<style scoped>
.all {
  min-width: 960px;
}

.tile {
  width: 19.5vw;
  height: 13.65vw;
  min-height: 131px;
  min-width: 187px;
  margin: 5px;
  background: rgb(0, 0, 0);
  display: inline-block;
  position: relative;
  transition: all 0.4s ease-out;
  overflow: hidden;
}

.tile img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: all 0.4s ease-out;
}

.tile .text {
  color: #fff;
  text-align: left;
  z-index: 5;
  padding: 10px;
}

@media screen and (max-width: 576px) {
  .tile .text,.tile p{
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .tile .text,.tile p{
    font-size: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .tile .text,.tile p{
    font-size: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .tile .text,.tile p{
    font-size: 14px;
  }
}

.tile h4 {
  margin: 5px 0;
}

.tile p {
  transform: translateX(200px);
}

.animate-text {
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.tile:hover {
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.6);
  transform: scale(1.05);
  z-index: 5;
}

.tile:hover img {
  opacity: 0.5;
}

.tile:hover .animate-text {
  transform: translateX(0);
  opacity: 1;
}

.tile:hover span {
  opacity: 1;
  transform: translateY(0);
}

.main {
  margin: 60px 0 0 0;
  padding: 0;
}

a {
  color: #00a5ff;
}

a:active {
  color: #ff6400;
}
</style>